import React from 'react'
import { Text, Box, Link } from 'theme-ui'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'

const IndexPage: React.FC = () => {
  return (
    <IndexLayout>
      <Box
        sx={{
          background:
            '#d4d4d4 url(josefpaijBG.jpg) no-repeat fixed bottom center',
          minHeight: '100vh',
          zIndex: 0,
          color: '#484848',
        }}
      >
        <Page>
          <Box mx="auto" sx={{ width: 640, position: 'relative' }}>
            <Box
              mr={61}
              sx={{
                background: 'url(josef2copy.gif) top left',
                position: 'absolute',
                top: 35,
                height: 74,
                width: 74,
                right: 0,
                boxShadow: '0 5px 31px #999',
                ':hover': {
                  background: 'url(josef2copy.gif) top right',
                },
              }}
            />
            <Box
              mt={-10}
              ml={-15}
              mb={5}
              sx={{
                background: 'url(newHeader.png) top center',
                height: 266,
                width: 639,
                position: 'relative',
                top: 61,
                zIndex: -1,
              }}
            />
          </Box>
          <Container>
            <Box sx={{ width: 640 }} mx="auto">
              <Box className="item-title">
                <Link ml={-26} href="https://github.com/josefpaij">
                  github.com/josepaij
                </Link>
              </Box>
              <Box mt={4} className="item-title">
                <Link ml={-26} href="https://vimeo.com/paij">
                  videos
                </Link>
              </Box>
              <Box className="video">
                (incarnation)
                <iframe
                  src="https://player.vimeo.com/video/13118631?title=0&amp;byline=0&amp;portrait=0"
                  frameBorder="0"
                  height="360"
                  width="640"
                />
              </Box>
              <Text mt={7} sx={{ fontSize: 11, textAlign: 'center' }}>
                copyright &copy; 2020 Joseph Page
              </Text>
            </Box>
          </Container>
        </Page>
      </Box>
    </IndexLayout>
  )
}

export default IndexPage
